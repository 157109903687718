import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ParticlesBackground from './ParticlesBackground';
import './App.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API,
  authDomain: process.env.REACT_APP_FIRE_DOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: "emailwrkrapp",
  storageBucket: "emailwrkrapp.appspot.com",
  messagingSenderId: "526739314158",
  appId: process.env.REACT_APP_FIRE_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const db = getDatabase(app);

const settingsList = [
  {
    title: "Auto-Filter",
    description: "Auto-Filtering uses the labels and categories you have used in the past, to organize emails now and in the future.",
    subdescription: "It is always learning, and if you add new categories or labels in the future, it will automatically start to use those",
    type: "choice",
    options: ["On", "Off"],
    name: "auto_filter_on",
    defaultValue: "On"
  },
  {
    title: "Auto-Filter Level",
    description: "Choose your filtering level from 1 to 10. Higher equals more organized.",
    subdescription: "The lowest setting, 1, will make Email Wrkr rarely categorize or label any of your email. 7 is the default",
    type: "slider",
    name: "auto_filter_level",
    defaultValue: 7
  },
  {
    title: "Auto-Filter Start",
    description: "How aggressive should the filter be?",
    type: "slider",
    name: "auto_filter_level",
    defaultValue: 7
  },
  // ... add other settings here
];

const SettingsSlide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedOption, setSelectedOption] = useState(settingsList[currentSlide].defaultValue);
  const [sliderValue, setSliderValue] = useState(settingsList[currentSlide].defaultValue);
  const [userId, setUserId] = useState(null);
  
  // Handle the authentication state.
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setUserId(user.uid);
      } else {
        // User is signed out
        // Handle accordingly, maybe redirect to login page or show a message
      }
    });

    // Cleanup the observer on component unmount
    return () => unsubscribe();
  }, []);

  // Update the selectedOption and sliderValue when the slide changes.
  useEffect(() => {
    setSelectedOption(settingsList[currentSlide].defaultValue);
    if (settingsList[currentSlide].type === "slider") {
        setSliderValue(settingsList[currentSlide].defaultValue);
    }
  }, [currentSlide]);

  const renderInput = (setting) => {
    switch (setting.type) {
      case "choice":
        return (
          <>
            {setting.subdescription && (
              <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-30px' }}>
                {setting.subdescription}
              </p>
            )}
            {setting.options.map((option, index) => (
              <label key={index} className="optionLabel">
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => setSelectedOption(option)}
                  className="radio"
                />
                {option}
              </label>
            ))}
          </>
        );
      case "slider":
        return (
          <div>
            {setting.subdescription && (
              <p style={{ fontStyle: 'italic', fontSize: '0.8rem', color: '#888', marginTop: '-3.2rem' }}>
                {setting.subdescription}
              </p>
            )}
            <input 
              type="range" 
              min="1" 
              max="10" 
              value={sliderValue} 
              onChange={(e) => setSliderValue(e.target.value)} 
              className="slider"
            />
            <span>{sliderValue}</span>
          </div>
        );
      default:
        return null;
    }
  };
  
  const saveSetting = () => {
    const setting = settingsList[currentSlide];
    let dataToSave;

    if (setting.type === "choice" && selectedOption !== null) {
      dataToSave = selectedOption;
    } else if (setting.type === "slider") {
      dataToSave = sliderValue;
    } else {
      alert("Please make a selection before saving.");
      return;
    }

    set(ref(db, `users/${userId}/settings/${setting.name}`), dataToSave);

    if (currentSlide < settingsList.length - 1) {
      setCurrentSlide(currentSlide + 1);
      setSelectedOption(null); // Reset for next slide
    } else {
      alert("All settings saved!");
    }
  };

  return (
    <div className="container">
      <ParticlesBackground />
      <div className="slide">
        <h2>{settingsList[currentSlide].title}</h2>
        <p>{settingsList[currentSlide].description}</p>
        {renderInput(settingsList[currentSlide])}
        <button onClick={saveSetting} className="button">Save</button>
      </div>
    </div>
  );
};

export default SettingsSlide;